import { useEffect, useState } from 'react';

import { getWhitelist } from '../helpers/Api';
import { ContractProvider } from '../types/ContractProvider';
import { IWhitelistSignature } from '../types/IWhitelistSignature';
import { onChainProvider } from '../types/OnChainProvider';
import { useContractContext } from './useContractContext';
import { useWeb3Context } from './useWeb3Context';

function useWhitelist(refresh: boolean = false, presaleClaim: number) {
    const { address, provider }: onChainProvider = useWeb3Context();
    const [isWhitelisted, setIsWhitelisted] = useState(false);
    const [whitelistIsLoading, setWhitelistIsLoading] = useState(true);
    const [whitelistNodeUnreachable, setWhitelistNodeUnreachable] = useState(false);
    const [whitelistNodeUnreachableIsLoading, setWhitelistNodeUnreachableIsLoading] = useState(true);
    const [whitelistSignature, setWhitelistSignature] = useState('');
    const [whitelistTotalClaim, setWhitelistTotalClaim] = useState('');
    const [whitelistContractAddress, setWhitelistContractAddress] = useState('');
    const { activeContract }: ContractProvider = useContractContext();

    useEffect(() => {
        const fetchWhitelist = async () => {
            try {
                if (address && provider && activeContract === 2) {
                    // presaleClaim 1 [activeContract === 2] = presaleClaimFirstAddress
                    // presaleClaim 2 [activeContract === 3] = presaleClaimSecondAddress
                    const gWhitelist: IWhitelistSignature | null = await getWhitelist(presaleClaim, address);
                    setWhitelistIsLoading(false);
                    setWhitelistNodeUnreachableIsLoading(false);

                    if (gWhitelist && gWhitelist.signature) {
                        setIsWhitelisted(true);
                        setWhitelistSignature(gWhitelist.signature);
                        setWhitelistContractAddress(gWhitelist.contractAddress);
                        setWhitelistTotalClaim(gWhitelist.totalClaim.toString())
                    }
                } else if (!address && provider) {
                    setWhitelistNodeUnreachableIsLoading(false);
                }
            } catch {
                setWhitelistIsLoading(false);
                setWhitelistNodeUnreachable(true);
                setWhitelistNodeUnreachableIsLoading(false);
                console.log('catch')
            }
        }

        fetchWhitelist();
    }, [activeContract, address, presaleClaim, provider, refresh])

    return {
        isWhitelisted,
        whitelistContractAddress,
        whitelistSignature,
        whitelistTotalClaim,
        whitelistIsLoading,
        whitelistNodeUnreachable,
        whitelistNodeUnreachableIsLoading
    }
}

export default useWhitelist;