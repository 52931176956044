import BigNumber from 'bignumber.js';
import { Contract } from 'ethers';
import { useEffect, useState } from 'react';

import { BIG_DECIMAL_18 } from '../utils/constants';

export const useTokenPrice = (refresh: boolean = false, contract: Contract) => {
    const [tokenPrice, setTokenPrice] = useState<number>(0)

    useEffect(() => {
        const fetchTokenPrice = async () => {
            try {
                const tPrice = await contract.pricePerToken();
                const tokenPriceInNumber = new BigNumber(tPrice.toString()).div(BIG_DECIMAL_18).toNumber();
                setTokenPrice(tokenPriceInNumber);
            } catch {
                console.log('ERROR fetchTokenPrice')
            }
        }

        fetchTokenPrice();

    }, [contract, refresh])

    return {
        tokenPrice
    }
};