import { chain } from "../../utils/constants";
import { MenuItem } from "../../types/MenuItem";

export const menuItems: MenuItem[] = [
    {
        contractIndex: 0, // PRESALE_CONTRACT_FIRST
        name: 'Presale',
        contractAddress: chain.PRESALE_CONTRACT_FIRST.ADDRESS
    },
    {
        contractIndex: 2, // PRESALECLAIM_CONTRACT_FIRST
        name: 'Claim',
        contractAddress: chain.PRESALECLAIM_CONTRACT_FIRST.ADDRESS
    }
]