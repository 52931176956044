import BigNumber from 'bignumber.js';
import { useCallback, useEffect, useState } from 'react';

import iconDai from '../assets/images/dai-logo.svg';
import iconUsdt from '../assets/images/tether-usdt-logo.svg';
import iconUsdc from '../assets/images/usdc-logo.svg';
import { getAllTransactions } from '../helpers/Api';
import { onChainProvider } from '../types/OnChainProvider';
import { Payment } from '../types/Payment';
import { BIG_DECIMAL_18, chain } from '../utils/constants';
import { useAvailableTokens } from './useAvailableTokens';
import { useClaimDisabled } from './useClaimDisabled';
import { useContract } from './useContract';
import { useDeployedOnBlock } from './useDeployedOnBlock';
import { usePaymentInfo } from './usePaymentInfo';
import { usePurchaseDisabled } from './usePurchaseDisabled';
import { useTimer } from './useTimer';
import { useTokenPrice } from './useTokenPrice';
import { useWeb3Context } from './useWeb3Context';

export const usePresaleFirst = (refresh: boolean = false) => {
    const { address, provider }: onChainProvider = useWeb3Context();
    const {
        contractWithProviderPresaleFirst,
        tokenContractWithProvider,
        contractWithSignerDai,
        contractWithProviderDai,
        contractWithSignerUsdt,
        contractWithProviderUsdt,
        contractWithSignerUsdc,
        contractWithProviderUsdc
    } = useContract();
    const { deployedOnBlock } = useDeployedOnBlock(refresh, contractWithProviderPresaleFirst);
    const [canClaimPresaleFirst, setCanClaimPresaleFirst] = useState<boolean>(false)
    const [claimedAllPresaleFirst, setClaimedAllPresaleFirst] = useState<boolean>(false)
    const [claimableAmountPresaleFirst, setClaimableAmountPresaleFirst] = useState<number>(0)
    const [transactionsDatapresaleFirst, setTransactionsDatapresaleFirst] = useState<any>([])
    const [tokensBoughtPresaleFirst, setTokensBoughtPresaleFirst] = useState<BigNumber>(new BigNumber("0"))
    const [tokensPerWalletPresaleFirst, setTokensPerWalletPresaleFirst] = useState<BigNumber>(new BigNumber("0"))
    const deployedOnBlockPresaleFirst = deployedOnBlock;

    const { timer } = useTimer(refresh, contractWithProviderPresaleFirst);
    const timerPresaleFirst = timer;

    const { claimDisabled } = useClaimDisabled(refresh, contractWithProviderPresaleFirst);
    const claimDisabledPresaleFirst = claimDisabled;

    const { purchaseDisabled } = usePurchaseDisabled(refresh, contractWithProviderPresaleFirst);
    const purchaseDisabledPresaleFirst = purchaseDisabled;

    const { tokensLeft, tokensLeftIsLoading, tokensSold, tokensSoldIsLoading } = useAvailableTokens(refresh, contractWithProviderPresaleFirst);
    const tokensLeftPresaleFirst = tokensLeft;
    const tokensLeftIsLoadingPresaleFirst = tokensLeftIsLoading;
    const tokensSoldPresaleFirst = tokensSold;
    const tokensSoldPresaleFirstIsLoading = tokensSoldIsLoading;

    const { tokenPrice } = useTokenPrice(refresh, contractWithProviderPresaleFirst);
    const tokenPricePresaleFirst = tokenPrice;

    const listOfPaymentsPresaleFirst: Payment[] = [
        {
            name: 'DAI',
            contractAddress: chain.DAI_TOKEN.ADDRESS,
            contractSigner: contractWithSignerDai,
            contractProvider: contractWithProviderDai,
            icon: iconDai
        },
        {
            name: 'USDT',
            contractAddress: chain.USDT_TOKEN.ADDRESS,
            contractSigner: contractWithSignerUsdt,
            contractProvider: contractWithProviderUsdt,
            icon: iconUsdt
        },
        {
            name: 'USDC',
            contractAddress: chain.USDC_TOKEN.ADDRESS,
            contractSigner: contractWithSignerUsdc,
            contractProvider: contractWithProviderUsdc,
            icon: iconUsdc
        }
    ];

    const { allPayments } = usePaymentInfo(refresh, contractWithProviderPresaleFirst, listOfPaymentsPresaleFirst);
    const allPaymentsPresaleFirst = allPayments;

    const resetValues = useCallback(() => {
        if (canClaimPresaleFirst) {
            setCanClaimPresaleFirst(false);
            setClaimableAmountPresaleFirst(0)
        }
    }, [canClaimPresaleFirst])

    useEffect(() => {
        getAllTransactions(provider).then((data: any) => {
            setTransactionsDatapresaleFirst(data);
        });
    }, [])

    useEffect(() => {
        const fetchCanClaim = async () => {
            if (address) {
                const presaler = await contractWithProviderPresaleFirst.presaler(address);
                const tokensBought = new BigNumber(presaler.tokensBought.toString())
                setTokensBoughtPresaleFirst(tokensBought.div(1E18));

                const maxTokensPerWallet = await contractWithProviderPresaleFirst.maxTokensPerWallet();
                setTokensPerWalletPresaleFirst(new BigNumber(maxTokensPerWallet.toString()).div(1E18));

                // Deployed on block > 0
                if (deployedOnBlock > 0) {
                    // const latestBlock = await provider.getBlock("latest")
                    // console.log('latestBlock', latestBlock.number)
                    // console.log('deployedOnBlock', deployedOnBlock)
                    // console.log('-----')
                    // console.log('deployedOnBlock', deployedOnBlock)
                    // console.log('latestBlock', latestBlock.number);
                    // console.log('-----')
                    const hasClaimedTotal = new BigNumber(presaler.hasClaimedInTotal.toString()).eq(tokensBought.toString());
                    // console.log('presaler.hasClaimedInTotal.toString()', presaler.hasClaimedInTotal.toString())
                    // console.log('tokensBought', tokensBought.toString())
                    // console.log('-----')

                    // hasClaimedInTotal < tokensBought
                    if (!hasClaimedTotal) {
                        // console.log('hasClaimedTotal', hasClaimedTotal)
                        // console.log('-----')
                        // tokensBought > 0
                        if (tokensBought.gt(0)) {
                            // tokens in contract > 0
                            const presaleContractTokenBalance = await tokenContractWithProvider.balanceOf(contractWithProviderPresaleFirst.address);
                            const presaleContractHasBalance = new BigNumber(presaleContractTokenBalance.toString()).gt(0);
                            if (presaleContractHasBalance) {
                                // console.log('presaleContractHasBalance', presaleContractHasBalance)
                                // console.log('presaleContractTokenBalance', presaleContractTokenBalance.toString())
                                // console.log('-----')
                                // hasClaimedInTotal > 0
                                // Checking if the user has already claimed his TGE
                                const [claimableAmount, hasClaimedInTotal] = await contractWithProviderPresaleFirst.getClaimable(address);
                                const hasTotalClaimable = new BigNumber(hasClaimedInTotal.toString()).gt(0);
                                if (hasTotalClaimable) {
                                    // console.log('totalClaimable.toString()', hasClaimedInTotal.toString());
                                    // console.log('claimableAmount.toString()', claimableAmount.toString());
                                    // console.log('hasTotalClaimable', hasTotalClaimable);
                                    // console.log('-----')
                                    const contractHasEnoughToClaim = new BigNumber(presaleContractTokenBalance.toString()).gte(claimableAmount.toString());
                                    const claimableAmnt = new BigNumber(claimableAmount.toString());
                                    // console.log('contractHasEnoughToClaim', contractHasEnoughToClaim);
                                    // console.log('presaleContractTokenBalance.toString()', presaleContractTokenBalance.toString());
                                    console.log('hasClaimedInTotal.toString()', hasClaimedInTotal.toString());
                                    // console.log('claimableAmnt', claimableAmnt.toNumber());
                                    // console.log('-----')
                                    if (contractHasEnoughToClaim && claimableAmount.gt(0)) {
                                        setCanClaimPresaleFirst(true);
                                        setClaimableAmountPresaleFirst(new BigNumber(claimableAmnt.toString()).div(BIG_DECIMAL_18).toNumber());
                                    } else {
                                        resetValues();
                                        setClaimedAllPresaleFirst(false);
                                    }
                                } else {
                                    resetValues();
                                    setClaimedAllPresaleFirst(false);
                                }
                            } else {
                                resetValues();
                                setClaimedAllPresaleFirst(false);
                            }
                        } else {
                            resetValues();
                            setClaimedAllPresaleFirst(false);
                        }
                    } else {
                        resetValues();
                        if (tokensBought.gt(0)) {
                            setClaimedAllPresaleFirst(true);
                        }
                    }
                } else {
                    resetValues();
                    setClaimedAllPresaleFirst(false);
                }
            }
        }

        fetchCanClaim();

    }, [address, deployedOnBlock, contractWithProviderPresaleFirst, provider, refresh, resetValues, tokenContractWithProvider, refresh])

    return {
        canClaimPresaleFirst,
        claimedAllPresaleFirst,
        claimableAmountPresaleFirst,
        deployedOnBlockPresaleFirst,
        timerPresaleFirst,
        claimDisabledPresaleFirst,
        purchaseDisabledPresaleFirst,
        tokensLeftPresaleFirst,
        tokensLeftIsLoadingPresaleFirst,
        tokenPricePresaleFirst,
        tokensBoughtPresaleFirst,
        listOfPaymentsPresaleFirst,
        allPaymentsPresaleFirst,
        tokensSoldPresaleFirst,
        tokensSoldPresaleFirstIsLoading,
        transactionsDatapresaleFirst,
        tokensPerWalletPresaleFirst
    }
};