import { ethers } from 'ethers';

import PRESALE_CONTRACT_ABI from '../assets/abi/PRESALE_CONTRACT_ABI.json';
import PRESALECLAIM_CONTRACT_ABI from '../assets/abi/PRESALECLAIM_CONTRACT_ABI.json';
import DAI_CONTRACT_ABI from '../assets/abi/DAI_CONTRACT_ABI.json';
import USDT_CONTRACT_ABI from '../assets/abi/USDT_CONTRACT_ABI.json';
import USDC_CONTRACT_ABI from '../assets/abi/USDC_CONTRACT_ABI.json';
import TOKEN_ABI from '../assets/abi/TOKEN_ABI.json';
import { Chain } from '../types/IAddresses';

export const NETWORK_ID = 1;
export const API_URL = "https://api.paracell.io";
export const INFURA_ID = "e6de0eba53304a908081299668634548";
export const DEAD_ADDRESS = "0x0000000000000000000000000000000000000000";
export const MAIN_ETHERSCAN_API_KEY = "X5HRH5YDH87X812MS9MYH3HD3BZBSHWYN4"
export const BLOCKS_PER_SECOND = 11.6; // 12 Secs

export const chainInfo: Chain = {
    1: {
        CHAIN:                       "mainnet",
        CHAIN_HEX:                   "0x1",
        CHAIN_NAME:                  "Ethereum Mainnet",
        ETHERSCAN_API:               "https://api.etherscan.io/",
        BLOCK_EXPLORER:              "https://etherscan.io",
        PROVIDER:                    `https://mainnet.infura.io/v3/${INFURA_ID}`,
        TOKEN: {
            ADDRESS:                 "",
            ABI:                     TOKEN_ABI as ethers.ContractInterface
        },
        USDT_TOKEN: {
            ADDRESS:                 "0xdAC17F958D2ee523a2206206994597C13D831ec7",
            ABI:                     USDT_CONTRACT_ABI as ethers.ContractInterface
        },
        USDC_TOKEN: {
            ADDRESS:                 "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
            ABI:                     USDC_CONTRACT_ABI as ethers.ContractInterface
        },
        DAI_TOKEN: {
            ADDRESS:                 "0x6B175474E89094C44Da98b954EedeAC495271d0F",
            ABI:                     DAI_CONTRACT_ABI as ethers.ContractInterface
        },
        PRESALE_CONTRACT_FIRST: {
            ADDRESS:                 "0xa0254D59de805614A95641b86C8699551D1BA6Af",
            ABI:                     PRESALE_CONTRACT_ABI as ethers.ContractInterface
        },
        PRESALECLAIM_CONTRACT_FIRST: {
            ADDRESS:                 "",
            ABI:                     PRESALECLAIM_CONTRACT_ABI as ethers.ContractInterface
        },
        PRESALECLAIM_CONTRACT_SECOND: {
            ADDRESS:                 "",
            ABI:                     PRESALECLAIM_CONTRACT_ABI as ethers.ContractInterface
        }
    },
    5: {
        CHAIN:                       "Goerli",
        CHAIN_NAME:                  "Goerli Testnet",
        CHAIN_HEX:                   "0x5",
        ETHERSCAN_API:               "https://api-goerli.etherscan.io/",
        BLOCK_EXPLORER:              "https://goerli.etherscan.io",
        PROVIDER:                    `https://goerli.infura.io/v3/${INFURA_ID}`,
        TOKEN: {
            ADDRESS:                 "",
            ABI:                     TOKEN_ABI as ethers.ContractInterface
        },
        USDT_TOKEN: {
            ADDRESS:                 "",
            ABI:                     USDT_CONTRACT_ABI as ethers.ContractInterface
        },
        USDC_TOKEN: {
            ADDRESS:                 "",
            ABI:                     USDC_CONTRACT_ABI as ethers.ContractInterface
        },
        DAI_TOKEN: {
            ADDRESS:                 "",
            ABI:                     DAI_CONTRACT_ABI as ethers.ContractInterface
        },
        PRESALE_CONTRACT_FIRST: {
            ADDRESS:                 "0xba631D5F5BC772a3031C97Af6514a70cc2d770d9",
            ABI:                     PRESALE_CONTRACT_ABI as ethers.ContractInterface
        },
        PRESALECLAIM_CONTRACT_FIRST: {
            ADDRESS:                 "",
            ABI:                     PRESALECLAIM_CONTRACT_ABI as ethers.ContractInterface
        },
        PRESALECLAIM_CONTRACT_SECOND: {
            ADDRESS:                 "",
            ABI:                     PRESALECLAIM_CONTRACT_ABI as ethers.ContractInterface
        }
    },
    31337: {
        CHAIN:                       "hardhat",
        CHAIN_NAME:                  "Hardhat Node",
        CHAIN_HEX:                   "0x7a69",
        ETHERSCAN_API:               "",
        BLOCK_EXPLORER:              "",
        PROVIDER:                    `http://127.0.0.1:8545/`,
        TOKEN: {
            ADDRESS:                 "0x1bcbfcD2C000FaeA4724A206720AcBbbD2557B3c",
            ABI:                     TOKEN_ABI as ethers.ContractInterface
        },
        USDT_TOKEN: {
            ADDRESS:                 "0xd319776Cb43a904B743e47C2Ea46475DBadd3367",
            ABI:                     USDT_CONTRACT_ABI as ethers.ContractInterface
        },
        USDC_TOKEN: {
            ADDRESS:                 "0xc498C6A4668e4F1C17229ff9Fab182F252672221",
            ABI:                     USDC_CONTRACT_ABI as ethers.ContractInterface
        },
        DAI_TOKEN: {
            ADDRESS:                 "0xf5Eff39e6e0B495Bb62Ae536541147E07E173E66",
            ABI:                     DAI_CONTRACT_ABI as ethers.ContractInterface
        },
        PRESALE_CONTRACT_FIRST: {
            ADDRESS:                 "0xa0254D59de805614A95641b86C8699551D1BA6Af",
            ABI:                     PRESALE_CONTRACT_ABI as ethers.ContractInterface
        },
        PRESALECLAIM_CONTRACT_FIRST: {
            ADDRESS:                 "",
            ABI:                     PRESALECLAIM_CONTRACT_ABI as ethers.ContractInterface
        },
        PRESALECLAIM_CONTRACT_SECOND: {
            ADDRESS:                 "",
            ABI:                     PRESALECLAIM_CONTRACT_ABI as ethers.ContractInterface
        },
    }
}


export const BIG_DECIMAL_18 = 1E18;

export const chain = chainInfo[NETWORK_ID];