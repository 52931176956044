import { Contract } from 'ethers';
import { useEffect, useState } from 'react';

export const usePurchaseDisabled = (refresh: boolean = false, contract: Contract) => {
    const [purchaseDisabled, setPurchaseDisabled] = useState<boolean>(false)

    useEffect(() => {
        const fetchPurchaseDisabled = async () => {
            try {
                const pcDisabled = await contract.purchaseDisabled();
                setPurchaseDisabled(pcDisabled);
            } catch {
                console.log('ERROR fetchPurchaseDisabled')
            }
        }

        fetchPurchaseDisabled();
    }, [contract, refresh])

    return {
        purchaseDisabled
    }
};