import React, { useMemo, useState } from "react";
import { ContractContextData } from "../types/ContractContextData";

export const ContractContext = React.createContext<ContractContextData>(null);

export const ContractContextProvider: React.FC<{ children: React.ReactElement }> = ({ children }) => {
    const [activeContract, setActiveContract] = useState(0)

    const contractProvider = useMemo(() => ({ activeContract, setActiveContract }), [activeContract, setActiveContract]);

    return <ContractContext.Provider value={{ contractProvider }}>{children}</ContractContext.Provider>;
}