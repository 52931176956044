/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useContractContext } from '../../hooks/useContractContext';
import { ContractProvider } from '../../types/ContractProvider';
import { MenuItem } from '../../types/MenuItem';

import ConnectButton from '../ConnectButton/ConnectButton';
import { menuItems } from './MenuItems';
import logo from '../../assets/images/para-logo.svg';

interface Props {
  buyIsLoading: boolean;
  claimIsLoading: boolean;
}

const Menu: React.FC<Props> = (props) => {
  const { buyIsLoading, claimIsLoading }: Props = props;
  const { activeContract, setActiveContract }: ContractProvider = useContractContext();

  const onMenuClick = (contractIndex: number) => {
    setActiveContract(contractIndex)

    if (window.innerWidth <= 768) {
      const body = document.body;
      if (body && body.classList.contains('menu-open')) {
        body.classList.add('menu-ani');
        setTimeout(() => {
          body.classList.remove('menu-open');
          body.classList.remove('menu-ani');
        }, 300)
      }
    }
  }

  return (
    <div id="navbar">
      <div className="container">
        <div className="navbar-header">
          <a className="logo-main" href="/">
            <img src={logo} alt="Para" />
          </a>
          <button type="button" className="nav-toggle nav-trigger">
            <div className="nav-icon"><span></span></div>
          </button>
          <div className="nav-wrap">
            <nav id="nav-main" className="nav-full">
              <ul>
                <li><a href="http://paracell.io/" target="_blank" title="Website" rel="noreferrer">Website</a></li>
                {
                  menuItems.map((item: MenuItem, idx: number) => {
                    return <li
                      key={item.contractIndex}
                      className={`${activeContract === item.contractIndex ? 'active' : ''} ${buyIsLoading || claimIsLoading ? 'disabled' : ''}`}
                      onClick={() => onMenuClick(item.contractIndex)}>
                      <a className={`n-link-${idx}`} href="#">{item.name}</a>
                    </li>
                  })
                }
                <li className="show-mob"><a href="https://t.me/+02uPdfZ4q0Q1ZWVi" target="_blank" title="Para Telegram" rel="noreferrer">Telegram</a></li>
                <li className="show-mob"><a href="https://www.instagram.com/paraworldofficial/" target="_blank" title="Para Instagram" rel="noreferrer">Instagram</a></li>
                <li className="show-mob"><a href="https://twitter.com/ParaWorld_NFT" target="_blank" title="Para Twitter" rel="noreferrer">Twitter</a></li>
                <li className="show-mob"><a href="https://discord.gg/5Ux5fUY75Y" target="_blank" title="Para Discord" rel="noreferrer">Discord</a></li>
                <li className="show-mob"><a href="https://www.youtube.com/channel/UC70eME2wzg_Vka1B6GG6Ulg" target="_blank" title="Para YouTube" rel="noreferrer">YouTube</a></li>
              </ul>
            </nav>
            <nav id="nav-right">
              <div className="nav-right-buttons">
                <ConnectButton />
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Menu
