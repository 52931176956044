import BigNumber from 'bignumber.js';
import { Contract } from 'ethers';
import { useEffect, useState } from 'react';

export const useAvailableTokens = (refresh: boolean = false, contract: Contract) => {
    const [availableTokens, setAvailableTokens] = useState<number>(0)
    const [tokensLeft, setTokensLeft] = useState<number>(0)
    const [tokensSold, setTokensSold] = useState<number>(0)
    const [tokensLeftIsLoading, setTokensLeftIsLoading] = useState<boolean>(true);
    const [tokensSoldIsLoading, setTokensSoldIsLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchTokenPrice = async () => {
            try {
                const aTokens = await contract.availableTokens();
                const aTokensConverted = new BigNumber(aTokens.toString()).toNumber();
                setAvailableTokens(aTokensConverted);
                
                const sTokens = await contract.tokensSold();
                const sTokensConverted = new BigNumber(sTokens.toString()).toNumber();
                
                
                if(sTokensConverted) {
                    setTokensSold(sTokensConverted)
                    setTokensSoldIsLoading(false);
                } else {
                    setTokensSoldIsLoading(false);
                }

                if (aTokensConverted) {
                    setTokensLeftIsLoading(false);
                    setTokensLeft(aTokensConverted);
                } else {
                    setTokensLeftIsLoading(false);
                }

            } catch {
                setTokensLeftIsLoading(false);
            }
        }

        fetchTokenPrice();
    }, [contract, refresh])

    return {
        availableTokens,
        tokensLeft,
        tokensSold,
        tokensLeftIsLoading,
        tokensSoldIsLoading
    }
};