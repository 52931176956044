import { StaticJsonRpcProvider } from "@ethersproject/providers";
import { ethers } from "ethers";
import { chain } from "./constants";

export const tokenContract = (provider: StaticJsonRpcProvider | ethers.providers.JsonRpcSigner) => {
    return new ethers.Contract(chain.TOKEN.ADDRESS as string, chain.TOKEN.ABI, provider);
}

export const daiContract = (provider: StaticJsonRpcProvider | ethers.providers.JsonRpcSigner) => {
    return new ethers.Contract(chain.DAI_TOKEN.ADDRESS as string, chain.DAI_TOKEN.ABI, provider);
}

export const usdtContract = (provider: StaticJsonRpcProvider | ethers.providers.JsonRpcSigner) => {
    return new ethers.Contract(chain.USDT_TOKEN.ADDRESS as string, chain.USDT_TOKEN.ABI, provider);
}

export const usdcContract = (provider: StaticJsonRpcProvider | ethers.providers.JsonRpcSigner) => {
    return new ethers.Contract(chain.USDC_TOKEN.ADDRESS as string, chain.USDC_TOKEN.ABI, provider);
}

export const presaleContractFirst = (provider: StaticJsonRpcProvider | ethers.providers.JsonRpcSigner) => {
    return new ethers.Contract(chain.PRESALE_CONTRACT_FIRST.ADDRESS as string, chain.PRESALE_CONTRACT_FIRST.ABI, provider);
}

export const presaleClaimContractFirst = (provider: StaticJsonRpcProvider | ethers.providers.JsonRpcSigner) => {
    if(chain.PRESALECLAIM_CONTRACT_FIRST.ADDRESS.length > 0) return new ethers.Contract(chain.PRESALECLAIM_CONTRACT_FIRST.ADDRESS as string, chain.PRESALECLAIM_CONTRACT_FIRST.ABI, provider);
    return '';
}

export const presaleClaimContractSecond = (provider: StaticJsonRpcProvider | ethers.providers.JsonRpcSigner) => {
    if(chain.PRESALECLAIM_CONTRACT_SECOND.ADDRESS.length > 0) return new ethers.Contract(chain.PRESALECLAIM_CONTRACT_SECOND.ADDRESS as string, chain.PRESALECLAIM_CONTRACT_FIRST.ABI, provider);
    return '';
}