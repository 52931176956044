import { Contract } from 'ethers';
import { useEffect, useState } from 'react';

export const useClaimDisabled = (refresh: boolean = false, contract: Contract | string) => {
    const [claimDisabled, setClaimDisabled] = useState<boolean>(false)

    useEffect(() => {
        const fetchClaimDisabled = async () => {
            if (typeof contract === 'string') return console.log('ERROR getBlocksLeft');
            try {
                const clDisabled = await contract.claimDisabled();
                setClaimDisabled(clDisabled);
            } catch {
                console.log('ERROR fetchClaimDisabled')
            }
        }

        fetchClaimDisabled();
    }, [contract, refresh])

    return {
        claimDisabled
    }
};