import BigNumber from 'bignumber.js';
import { ethers } from 'ethers';
import { useEffect, useState } from 'react';

import { onChainProvider } from '../types/OnChainProvider';
import { Payment } from '../types/Payment';
import { daiContract, usdcContract, usdtContract } from '../utils/contracts';
import { useWeb3Context } from './useWeb3Context';

export const usePaymentInfo = (refresh: boolean = false, contract: ethers.Contract, payments: Payment[]) => {
    const { provider, address }: onChainProvider = useWeb3Context();
    const [allPayments, setAllPayments] = useState()

    useEffect(() => {
        const getPaymentInfo = async () => {
            let allPaym: any = {};
            for (const payment of payments) {
                if (contract && payment) {
                    const paymentInfo = await contract.payment(payment.contractAddress);

                    let balance = 0;
                    let buyAllowance = 0;
                    if (address) {
                        let paymentContract;
                        const signer = provider.getSigner(address);
                        switch (payment.name) {
                            case 'USDC':
                                paymentContract = usdcContract(signer);
                                break;
                            case 'USDT':
                                paymentContract = usdtContract(signer);
                                break;
                            case 'DAI':
                                paymentContract = daiContract(signer);
                                break;
                            default:
                                paymentContract = usdtContract(signer);
                                break;
                        }
                        balance = await paymentContract.balanceOf(address)
                        buyAllowance = await paymentContract.allowance(address, contract.address)
                    }

                    allPaym[payment.contractAddress.toLowerCase()] = {
                        ...paymentInfo,
                        balance: balance.toString(),
                        balanceConverted: new BigNumber(balance.toString()).div((10 ** paymentInfo.tokenDecimal)).toString(),
                        hasBuyAllowance: new BigNumber(buyAllowance.toString()).gt(0),
                        buyAllowance: new BigNumber(buyAllowance.toString()).toString()
                    };
                }
            }
            setAllPayments(allPaym);
        }

        getPaymentInfo();
    }, [address, refresh])

    return {
        allPayments
    }
};