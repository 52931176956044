import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

import { truncateFromCenter } from '../../helpers/SharedFunctions';
import { useWeb3Context } from '../../hooks/useWeb3Context';
import { onChainProvider } from '../../types/OnChainProvider';
import Button from '../Button/Button';

interface Props {
    disabled?: boolean;
    className?: string;
    icon?: JSX.Element;
    text?: string
}

const ConnectButton: React.FC<Props> = (props) => {
    const { text, disabled, className, icon }: Props = props;
    const { address, connect, disconnect, connected, hasToSwitchNetwork, switchNetwork, switchingNetworkIsLoading }: onChainProvider = useWeb3Context();
    const [openWalletInfo, setOpenWalletInfo] = useState(false);

    return (
        <React.Fragment>
            <Modal className='wallet-info-modal' show={openWalletInfo} onHide={() => setOpenWalletInfo(!openWalletInfo)}>
                <Modal.Body className='no-pad'>
                    <div className="banner-sub-block ">
                        <div className="banner-sub-main">
                            <h3>WALLET INFORMATION</h3>
                        </div>
                        <div className="banner-address">{address}</div>
                        <div className="banner-sub-buttons sub-buttons-right sub-buttons-single">
                            <Button className="button" onClick={() => disconnect()}>
                                <span>Disconnect</span>
                            </Button>
                            <Button className="button l-btn" onClick={() => setOpenWalletInfo(!openWalletInfo)}>
                                <span>Close</span>
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Button
                disabled={disabled || switchingNetworkIsLoading}
                className={`${className ? className : 'button btn-main'}`}
                onClick={() => hasToSwitchNetwork ? switchNetwork() : !connected ? connect() : setOpenWalletInfo(!openWalletInfo)}>
                {icon ? icon : null}
                {
                    switchingNetworkIsLoading
                        ? 'Switching network...'
                        : hasToSwitchNetwork
                            ? 'Switch Network' :
                            !connected
                                ? text ? text : 'Connect Wallet'
                                : truncateFromCenter(address)
                }
            </Button>
        </React.Fragment>
    )
}

export default ConnectButton;