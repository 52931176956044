import ReactDOM from 'react-dom'
import Routing from './router/Router'
import reportWebVitals from './reportWebVitals'
import './theme/global.scss'
import './theme/main.scss'
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/pagination/pagination.scss'
import 'swiper/components/scrollbar/scrollbar.scss'
import 'react-toastify/dist/ReactToastify.css';
import History from './helpers/History'

import { Router } from 'react-router-dom'
import { Web3ContextProvider } from './context/web3Context'
import { ContractContextProvider } from './context/contractContext'
import { ToastContainer } from 'react-toastify';

ReactDOM.render(
  <ContractContextProvider>
    <Web3ContextProvider>
      <Router history={History}>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Routing />
      </Router>
    </Web3ContextProvider>
  </ContractContextProvider>,
  document.getElementById('outer-wrapper'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
