import BigNumber from 'bignumber.js';
import { useCallback, useEffect, useState } from 'react';

import { onChainProvider } from '../types/OnChainProvider';
import { BIG_DECIMAL_18 } from '../utils/constants';
import { useClaimDisabled } from './useClaimDisabled';
import { useContract } from './useContract';
import { useDeployedOnBlock } from './useDeployedOnBlock';
import { useTimer } from './useTimer';
import { useWeb3Context } from './useWeb3Context';
import useWhitelist from './useWhitelist';

export const usePresaleClaimSecond = (refresh: boolean = false) => {
    const { address, provider }: onChainProvider = useWeb3Context();
    const { contractWithProviderPresaleClaimSecond, tokenContractWithProvider } = useContract();
    const { deployedOnBlock } = useDeployedOnBlock(refresh, contractWithProviderPresaleClaimSecond);
    const [canClaimPresaleClaimSecond, setCanClaimPresaleClaimSecond] = useState<boolean>(false)
    const [claimedAllPresaleClaimSecond, setClaimedAllPresaleClaimSecond] = useState<boolean>(false)
    const [claimableAmountPresaleClaimSecond, setClaimableAmountPresaleClaimSecond] = useState<number>(0)
    const [tokensBoughtPresaleClaimSecond, setTokensBoughtPresaleClaimSecond] = useState<BigNumber>(new BigNumber("0"))
    const { isWhitelisted, whitelistNodeUnreachable, whitelistIsLoading, whitelistNodeUnreachableIsLoading, whitelistSignature, whitelistTotalClaim } = useWhitelist(refresh, 2);
    const deployedOnBlockPresaleClaimSecond = deployedOnBlock;
    const isWhitelistedPresaleClaimSecond = isWhitelisted;
    const whitelistNodeUnreachablePresaleClaimSecond = whitelistNodeUnreachable;
    const whitelistIsLoadingPresaleClaimSecond = whitelistIsLoading;
    const whitelistNodeUnreachableIsLoadingPresaleClaimSecond = whitelistNodeUnreachableIsLoading;
    const whitelistSignaturePresaleClaimSecond = whitelistSignature;
    const whitelistTotalClaimPresaleClaimSecond = whitelistTotalClaim;

    const { timer } = useTimer(refresh, contractWithProviderPresaleClaimSecond);
    const timerPresaleClaimSecond = timer;

    const { claimDisabled } = useClaimDisabled(refresh, contractWithProviderPresaleClaimSecond);
    const claimDisabledPresaleClaimSecond = claimDisabled;

    const resetValues = useCallback(() => {
        if (canClaimPresaleClaimSecond) {
            setCanClaimPresaleClaimSecond(false);
            setClaimableAmountPresaleClaimSecond(0)
        }
    }, [canClaimPresaleClaimSecond])

    useEffect(() => {
        const fetchCanClaim = async () => {
            if (typeof contractWithProviderPresaleClaimSecond === 'string') return console.log('ERROR fetchCanClaim');
            if (address && whitelistTotalClaim && new BigNumber(whitelistTotalClaim).gt(0)) {
                // Deployed on block > 0
                if (deployedOnBlock > 0) {
                    // const latestBlock = await provider.getBlock("latest")
                    // console.log('-----')
                    // console.log('deployedOnBlock', deployedOnBlock)
                    // console.log('latestBlock', latestBlock.number);
                    // console.log('-----')
                    const presaler = await contractWithProviderPresaleClaimSecond.presaler(address);
                    const tokensBought = new BigNumber(whitelistTotalClaim.toString())
                    setTokensBoughtPresaleClaimSecond(tokensBought);
                    const hasClaimedTotal = new BigNumber(presaler.toString()).eq(tokensBought.toString());
                    // console.log('presaler.toString()', presaler.toString())
                    // console.log('tokensBought', tokensBought.toString())
                    // console.log('-----')

                    // hasClaimedInTotal < tokensBought
                    if (!hasClaimedTotal) {
                        // console.log('hasClaimedTotal', hasClaimedTotal)
                        // console.log('tokensBought.gt(0)', tokensBought.gt(0))
                        // console.log('-----')
                        // tokensBought > 0
                        if (tokensBought.gt(0)) {
                            // tokens in contract > 0
                            const presaleContractTokenBalance = await tokenContractWithProvider.balanceOf(contractWithProviderPresaleClaimSecond.address);
                            const presaleContractHasBalance = new BigNumber(presaleContractTokenBalance.toString()).gt(0);
                            // console.log('presaleContractHasBalance', presaleContractHasBalance)
                            // console.log('presaleContractTokenBalance', presaleContractTokenBalance.toString())
                            // console.log('-----')
                            if (presaleContractHasBalance) {
                                // hasClaimedInTotal > 0
                                // Checking if the user has already claimed his TGE
                                const [claimableAmount, hasClaimedInTotal] = await contractWithProviderPresaleClaimSecond.getClaimable(address, whitelistTotalClaim);
                                const hasTotalClaimable = new BigNumber(hasClaimedInTotal.toString()).gt(0);
                                if (hasTotalClaimable) {
                                    // console.log('totalClaimable.toString()', hasClaimedInTotal.toString());
                                    // console.log('claimableAmount.toString()', claimableAmount.toString());
                                    // console.log('hasTotalClaimable', hasTotalClaimable);
                                    // console.log('-----')
                                    // tokens in contract >= totalClaimable
                                    const contractHasEnoughToClaim = new BigNumber(presaleContractTokenBalance.toString()).gte(claimableAmount.toString());
                                    const claimableAmnt = new BigNumber(claimableAmount.toString());
                                    // console.log('contractHasEnoughToClaim', contractHasEnoughToClaim);
                                    // console.log('presaleContractTokenBalance.toString()', presaleContractTokenBalance.toString());
                                    console.log('hasClaimedInTotal.toString()', hasClaimedInTotal.toString());
                                    // console.log('claimableAmnt', claimableAmnt.toNumber());
                                    // console.log('-----')
                                    if (contractHasEnoughToClaim && claimableAmount.gt(0)) {
                                        setCanClaimPresaleClaimSecond(true);
                                        setClaimableAmountPresaleClaimSecond(new BigNumber(claimableAmnt.toString()).div(BIG_DECIMAL_18).toNumber());
                                    } else {
                                        resetValues();
                                        setClaimedAllPresaleClaimSecond(false);
                                    }
                                } else {
                                    resetValues();
                                    setClaimedAllPresaleClaimSecond(false);
                                }
                            } else {
                                resetValues();
                                setClaimedAllPresaleClaimSecond(false);
                            }
                        } else {
                            resetValues();
                            setClaimedAllPresaleClaimSecond(false);
                        }
                    } else {
                        resetValues();
                        setClaimedAllPresaleClaimSecond(true);
                    }
                } else {
                    resetValues();
                    setClaimedAllPresaleClaimSecond(false);
                }
            }
        }

        fetchCanClaim();
    }, [address, deployedOnBlock, contractWithProviderPresaleClaimSecond, provider, resetValues, tokenContractWithProvider, whitelistTotalClaim, refresh])

    return {
        canClaimPresaleClaimSecond,
        claimedAllPresaleClaimSecond,
        claimableAmountPresaleClaimSecond,
        deployedOnBlockPresaleClaimSecond,
        isWhitelistedPresaleClaimSecond,
        whitelistNodeUnreachablePresaleClaimSecond,
        whitelistIsLoadingPresaleClaimSecond,
        whitelistNodeUnreachableIsLoadingPresaleClaimSecond,
        whitelistSignaturePresaleClaimSecond,
        whitelistTotalClaimPresaleClaimSecond,
        timerPresaleClaimSecond,
        claimDisabledPresaleClaimSecond,
        tokensBoughtPresaleClaimSecond
    }
};