import BigNumber from 'bignumber.js';
import { Contract } from 'ethers';
import { useEffect, useState } from 'react';

export const useDeployedOnBlock = (refresh: boolean = false, contract: Contract | string) => {
    const [deployedOnBlock, setDeployedOnBlock] = useState<number>(0)

    useEffect(() => {
        const fetchDeployedOnBlock = async () => {
            try {
                if(typeof contract === 'string') return console.log('ERROR fetchDeployedOnBlock');
                const deployedOnBlock = await contract.deployedOnBlock();
                const deployedOnBlockNumber = new BigNumber(deployedOnBlock.toString()).toNumber();
                setDeployedOnBlock(deployedOnBlockNumber);
            } catch {
                console.log('ERROR fetchDeployedOnBlock')
            }
        }

        fetchDeployedOnBlock();

    }, [contract, refresh])

    return {
        deployedOnBlock
    }
};