import { useContext, useMemo } from 'react';

import { ContractContext } from '../context/contractContext';

export const useContractContext = () => {
    const contractContext = useContext(ContractContext);
    if (!contractContext) {
        throw new Error(
            "useWeb3Context() can only be used inside of <Web3ContextProvider />, please declare it at a higher level.",
        );
    }
    const { contractProvider } = contractContext;
    return useMemo(() => {
        return { ...contractProvider };
    }, [contractProvider]);
};
