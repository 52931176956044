import BigNumber from 'bignumber.js';
import { Contract } from 'ethers';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';

import { onChainProvider } from '../types/OnChainProvider';
import { BLOCKS_PER_SECOND } from '../utils/constants';
import { useWeb3Context } from './useWeb3Context';

export const useTimer = (refresh: boolean = false, contract: Contract | string) => {
    const { provider }: onChainProvider = useWeb3Context();
    const [timer, setTimer] = useState<string>("");

    const getBlocksLeft = useCallback(async () => {
        if (typeof contract === 'string') return console.log('ERROR getBlocksLeft');
        if (contract) {

            const latestBlock = await provider.getBlock("latest")
            const latestBlockNumber = latestBlock.number;

            const dob = await contract.deployedOnBlock();
            const deployedOnBlock = new BigNumber(dob.toString()).toNumber();

            const bwfc = await contract.blockWaitForClaim();
            const blockWaitForClaim = new BigNumber(bwfc.toString()).toNumber();

            const runningBlocks = latestBlockNumber - deployedOnBlock;
            const periodsToClaim = Math.floor(runningBlocks / blockWaitForClaim);
            const blocksPast = runningBlocks - (periodsToClaim * blockWaitForClaim);
            const blocksLeft = blockWaitForClaim - blocksPast;
            const now = moment().unix();
            const blocksLeftInSeconds = Math.floor(blocksLeft * BLOCKS_PER_SECOND);
            const eventTime = now + blocksLeftInSeconds;

            const diffTime = eventTime - now;
            const duration = moment.duration(diffTime * 1000, 'milliseconds');
            const formatted = `${duration.days()}:${duration.hours()}:${duration.minutes()} for next claim`

            // console.log('-----')
            // console.log('contract', contract)
            // console.log('latestBlockNumber', latestBlockNumber)
            // console.log('deployedOnBlock', deployedOnBlock)
            // console.log('blockWaitForClaim', blockWaitForClaim)
            // console.log('runningBlocks', runningBlocks)
            // console.log('periodsToClaim', periodsToClaim)
            // console.log('blocksPast', blocksPast)
            // console.log('blocksLeft', blocksLeft)
            // console.log('now', now)
            // console.log('blocksLeftInSeconds', blocksLeftInSeconds)
            // console.log('duration', duration);
            // console.log('-----')

            setTimer(formatted);
        }
    }, [contract, provider]);

    useEffect(() => {
        getBlocksLeft().then();
    }, [getBlocksLeft, refresh])

    return {
        timer
    };
};