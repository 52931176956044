import { Grid, Typography, Zoom } from '@material-ui/core';
import BigNumber from 'bignumber.js';
import { Contract } from 'ethers';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import iconPara from '../../assets/images/para-white-icon-01.svg';
import Button from '../../components/Button/Button';
import ConnectButton from '../../components/ConnectButton/ConnectButton';
import Menu from '../../components/Menu/Menu';
import { catchErrorMessage, numberWithCommas, showToast } from '../../helpers/SharedFunctions';
import { useAddress } from '../../hooks/useAddress';
import { useContract } from '../../hooks/useContract';
import { useContractContext } from '../../hooks/useContractContext';
import { usePresaleClaimFirst } from '../../hooks/usePresaleClaimFirst';
import { usePresaleClaimSecond } from '../../hooks/usePresaleClaimSecond';
import { usePresaleFirst } from '../../hooks/usePresaleFirst';
import { useWeb3Context } from '../../hooks/useWeb3Context';
import { ContractProvider } from '../../types/ContractProvider';
import { IJsonRPCError } from '../../types/IJsonRPCError';
import { onChainProvider } from '../../types/OnChainProvider';
import { Payment } from '../../types/Payment';
import { daiContract, usdcContract, usdtContract } from '../../utils/contracts';

interface Props { }

const Presale: React.FC<Props> = (props) => {
    const {
        contractWithSignerPresaleFirst,
        contractWithSignerPresaleClaimFirst,
        contractWithSignerPresaleClaimSecond
    } = useContract();

    const [paymentInputSteps, setPaymentInputSteps] = useState<number>(0);
    const [paymentInput, setPaymentInput] = useState<number>(0);
    const [paymentBalance, setPaymentBalance] = useState<number>(0);
    const [paymentPerToken, setPaymentPerToken] = useState<number>(0);
    const [paymentBalanceIsLoading, setPaymentBalanceIsLoading] = useState<boolean>(true);
    const [tokenInput, setTokenInput] = useState<number>(1);
    const [buyIsLoading, setBuyIsLoading] = useState<boolean>(false);
    const [approveIsLoading, setApproveIsLoading] = useState<boolean>(false);
    const [claimIsLoadingPresaleFirst, setClaimIsLoadingPresaleFirst] = useState<boolean>(false);
    const [claimIsLoadingPresaleClaimFirst, setClaimIsLoadingPresaleClaimFirst] = useState<boolean>(false);
    const [claimIsLoadingPresaleClaimSecond, setClaimIsLoadingPresaleClaimSecond] = useState<boolean>(false);
    const [refreshAllHooks, setRefreshAllHooks] = useState<boolean>(false);

    // Hooks
    const { hasToSwitchNetwork, provider }: onChainProvider = useWeb3Context();
    const { register }: any = useParams();
    const { address } = useAddress();

    const { activeContract }: ContractProvider = useContractContext();

    const {
        claimableAmountPresaleFirst,
        claimedAllPresaleFirst,
        canClaimPresaleFirst,
        deployedOnBlockPresaleFirst,
        timerPresaleFirst,
        claimDisabledPresaleFirst,
        purchaseDisabledPresaleFirst,
        tokensLeftPresaleFirst,
        tokensLeftIsLoadingPresaleFirst,
        tokenPricePresaleFirst,
        tokensBoughtPresaleFirst,
        listOfPaymentsPresaleFirst,
        allPaymentsPresaleFirst,
        tokensSoldPresaleFirst,
        transactionsDatapresaleFirst,
        tokensPerWalletPresaleFirst
    } = usePresaleFirst(refreshAllHooks);

    const [activePayment, setActivePayment] = useState<Payment>(listOfPaymentsPresaleFirst[1]);

    const {
        claimableAmountPresaleClaimFirst,
        claimedAllPresaleClaimFirst,
        canClaimPresaleClaimFirst,
        deployedOnBlockPresaleClaimFirst,
        isWhitelistedPresaleClaimFirst,
        whitelistNodeUnreachablePresaleClaimFirst,
        whitelistIsLoadingPresaleClaimFirst,
        whitelistNodeUnreachableIsLoadingPresaleClaimFirst,
        whitelistSignaturePresaleClaimFirst,
        whitelistTotalClaimPresaleClaimFirst,
        timerPresaleClaimFirst,
        claimDisabledPresaleClaimFirst,
        tokensBoughtPresaleClaimFirst
    } = usePresaleClaimFirst(refreshAllHooks);

    const {
        claimableAmountPresaleClaimSecond,
        claimedAllPresaleClaimSecond,
        canClaimPresaleClaimSecond,
        deployedOnBlockPresaleClaimSecond,
        isWhitelistedPresaleClaimSecond,
        whitelistNodeUnreachablePresaleClaimSecond,
        whitelistIsLoadingPresaleClaimSecond,
        whitelistNodeUnreachableIsLoadingPresaleClaimSecond,
        whitelistSignaturePresaleClaimSecond,
        whitelistTotalClaimPresaleClaimSecond,
        timerPresaleClaimSecond,
        claimDisabledPresaleClaimSecond,
        tokensBoughtPresaleClaimSecond
    } = usePresaleClaimSecond(refreshAllHooks);

    const getReferral = useCallback(() => {
        if (register && register.includes('register=')) {
            const split = register.split('register=');
            const artist = split[1];
            if (artist.length > 0) {
                return { valid: true, artist: artist as string }
            }
            return { valid: false, artist: '' }
        }
        return { valid: false, artist: '' }
    }, [register]);

    const getTokenValue = useCallback((value: number, allPaymentsPresale: any | null) => {
        const inputToken = allPaymentsPresale[activePayment.contractAddress.toLowerCase()];
        const pricePerToken = new BigNumber(inputToken.pricePerToken.toString()).div((10 ** inputToken.tokenDecimal));
        return {
            tokenValue: new BigNumber(value).div(pricePerToken).toNumber(),
            price: pricePerToken,
            balance: inputToken.balanceConverted ? new BigNumber(inputToken.balanceConverted).toNumber() : 0
        }
    }, [activePayment.contractAddress])

    useEffect(() => {
        if (allPaymentsPresaleFirst && activeContract === 0) {
            const allPaymentsPresale: any = allPaymentsPresaleFirst;
            if (allPaymentsPresale) {
                const paymentToken: any = allPaymentsPresale[activePayment.contractAddress.toLowerCase()];
                if (paymentToken) {
                    const pricePerToken = new BigNumber(paymentToken.pricePerToken.toString()).div((10 ** paymentToken.tokenDecimal));
                    const { tokenValue } = getTokenValue(pricePerToken.toNumber(), allPaymentsPresaleFirst)
                    setTokenInput(parseInt(tokenValue.toString()));
                    setPaymentInput(pricePerToken.toNumber());
                    setPaymentPerToken(pricePerToken.toNumber())
                    if (pricePerToken.toNumber() > 0) {
                        setPaymentBalance(new BigNumber(paymentToken.balanceConverted).toNumber());
                        setTimeout(() => {
                            setPaymentBalanceIsLoading(false);
                        }, 1200)
                    }
                    setPaymentInputSteps(pricePerToken.toNumber());
                }
            }
        }
    }, [activePayment.contractAddress, allPaymentsPresaleFirst, activeContract, getTokenValue])

    const onPaymentInput = (e: any, allPaymentsPresale: any | null) => {
        const value = e.target.value;
        setPaymentInput(value);

        const { tokenValue } = getTokenValue(value, allPaymentsPresale);
        setTokenInput(parseInt(tokenValue.toString()));
    }

    const onPaymentMax = (allPaymentsPresale: any | null) => {
        const { balance, price } = getTokenValue(0, allPaymentsPresale)
        setPaymentInput(balance)

        const tInput = balance * price.toNumber();
        setTokenInput(parseInt(tInput.toString()));
    }

    const getPaymentTokenValue = (
        value: number,
        allPaymentsPresale: any | null
    ) => {
        const paymentToken = allPaymentsPresale[activePayment.contractAddress.toLowerCase()];
        const pricePerToken = new BigNumber(paymentToken.pricePerToken.toString()).div((10 ** paymentToken.tokenDecimal));
        return {
            tokenValue: new BigNumber(value).times(pricePerToken).toNumber(),
            price: pricePerToken,
            balance: paymentToken.balanceConverted ? new BigNumber(paymentToken.balanceConverted).toNumber() : 0
        }
    }

    const onTokenInput = (
        e: any,
        allPaymentsPresale: any | null
    ) => {
        const value = parseInt(e.target.value);
        setTokenInput(value);

        const { tokenValue } = getPaymentTokenValue(value, allPaymentsPresale)
        setPaymentInput(tokenValue);
    }

    const onTokenMax = (tokensLeftPresale: number, allPaymentsPresale: any | null) => {
        const { price } = getTokenValue(0, allPaymentsPresale)
        setTokenInput(parseInt(tokensLeftPresale.toString()))
        setPaymentInput(tokensLeftPresale * price.toNumber());
    }

    const handleErrorMessagesETH = (tokensLeftIsLoading: boolean) => {
        if (address && !(paymentBalanceIsLoading || tokensLeftIsLoading) && activePayment) {
            if (new BigNumber(paymentInput).gt(paymentBalance)) return `exceeds ${activePayment.name} balance`
            if (!paymentInput && paymentInput !== 0) return `${activePayment.name} is required`
        }
        return '';
    }

    const handleErrorMessagesPARA = (
        tokensLeft: number,
        tokensLeftIsLoading: boolean
    ) => {
        if (address && !(paymentBalanceIsLoading || tokensLeftIsLoading) && tokensLeft > 0) {
            const isReachingMaxTokensPerWallet = new BigNumber(tokenInput.toString()).gt(new BigNumber(tokensPerWalletPresaleFirst.toString()).minus(tokensBoughtPresaleFirst.toString()));
            const hasReachedMaximumTokens = new BigNumber(tokensBoughtPresaleFirst.toString()).isEqualTo(tokensPerWalletPresaleFirst.toString());
            if (isReachingMaxTokensPerWallet && !hasReachedMaximumTokens) return "Reaching maximum tokens"
            if (!tokenInput && tokenInput !== 0) return "PARA is required";
            if (new BigNumber(tokenInput).gt(tokensLeft)) return 'exceeds available tokens'
        }
        return '';
    }

    const disableBuyForPresaleContract = (
        tokensLeft: number,
        tokensLeftIsLoading: boolean,
        purchaseDisabled: boolean,
        ignoreTokensLeft: boolean
    ) => {
        if (new BigNumber(paymentInput).gt(paymentBalance)) return true
        if (paymentInput <= 0) return true;
        if (tokenInput <= 0) return true;
        if (buyIsLoading) return true;
        if (!ignoreTokensLeft) {
            if (tokensLeft <= 0) return true;
            if (new BigNumber(tokenInput).gt(tokensLeft)) return true
            if (handleErrorMessagesPARA(tokensLeft, tokensLeftIsLoading).length > 0) return true;
            if (handleErrorMessagesETH(tokensLeftIsLoading).length > 0) return true;
        }
        if (purchaseDisabled) return true;
        if (!allPaymentsPresaleFirst) return true;
        return false;
    }

    const onPresaleContractBuy = async (contract: Contract) => {
        setBuyIsLoading(true);
        const referral = getReferral();
        const quantity = new BigNumber(tokenInput).toNumber();

        const onSuccess = async (func: any) => {
            const txLog = await func.wait(); // Tx has been confirmed
            if (txLog) {
                setBuyIsLoading(false);
                setRefreshAllHooks(true);

                showToast(`Succesfully purchased: ${quantity} ${quantity > 1 ? 'tokens' : 'token'}`);
                setTimeout(() => {
                    setRefreshAllHooks(false);
                }, 2000)
            }
        }

        const onError = (err: unknown) => {
            const rpcError = err as IJsonRPCError
            const message = catchErrorMessage(rpcError);
            showToast(message, true);
            setBuyIsLoading(false);
        }

        if (referral.valid) {
            try {
                const buyTokensWithReferral = await contract.buyTokensWithReferral(referral.artist, activePayment.contractAddress, tokenInput);
                if (buyTokensWithReferral.hash) await onSuccess(buyTokensWithReferral);
            } catch (err: unknown) {
                onError(err);
            }
        } else {
            try {
                const buyTokens = await contract.buyTokens(activePayment.contractAddress, tokenInput);
                if (buyTokens.hash) await onSuccess(buyTokens);
            } catch (err: unknown) {
                onError(err);
            }
        }
    }

    const onPresaleContractApprove = async (contract: Contract, paymentToken: any) => {
        setApproveIsLoading(true);

        const approveNumber = "99999999999999999999999999999999999999999999999"
        const onSuccess = async (func: any) => {
            const txLog = await func.wait(); // Tx has been confirmed
            if (txLog) {
                setApproveIsLoading(false);
                setRefreshAllHooks(true);

                paymentToken.hasBuyAllowance = true;
                paymentToken.buyAllowance = approveNumber;
                showToast(`Succesfully approved`);
                setTimeout(() => {
                    setRefreshAllHooks(false);
                }, 2000)
            }
        }

        const onError = (err: unknown) => {
            const rpcError = err as IJsonRPCError
            const message = catchErrorMessage(rpcError);
            showToast(message, true);
            setApproveIsLoading(false);
        }

        try {
            let approveContract;
            const signer = provider.getSigner();
            switch (activePayment.name) {
                case 'USDC':
                    approveContract = usdcContract(signer);
                    break;
                case 'USDT':
                    approveContract = usdtContract(signer);
                    break;
                case 'DAI':
                    approveContract = daiContract(signer);
                    break;
                default:
                    approveContract = usdcContract(signer);
                    break;
            }
            const approve = await approveContract.approve(contract.address, "999999999999999999999999999999999999");
            if (approve.hash) await onSuccess(approve);
        } catch (err: unknown) {
            onError(err);
        }
    }

    const disableClaimForPresaleContract = (
        claimIsLoading: boolean,
        canClaim: boolean,
        claimDisabled: boolean,
        tokensLeft: number,
        tokensLeftIsLoading: boolean,
        purchaseDisabled: boolean
    ) => {
        if (disableBuyForPresaleContract(tokensLeft, tokensLeftIsLoading, purchaseDisabled, true)) return true;
        if (claimIsLoading) return true;
        if (!canClaim) return true;
        if (claimDisabled) return true;
        return false;
    }

    const onPresaleContractClaim = async (
        contract: Contract,
        claimedAmount: number,
        setClaimIsLoading: React.Dispatch<React.SetStateAction<boolean>>
    ) => {
        setClaimIsLoading(true);
        try {
            const claimTokens = await contract.claim();

            if (claimTokens.hash) { // Has hash
                const txLog = await claimTokens.wait(); // Tx has been confirmed
                if (txLog) {
                    setClaimIsLoading(false);
                    setRefreshAllHooks(true);

                    showToast(`Succesfully claimed: ${claimedAmount} ${claimedAmount > 1 ? 'tokens' : 'token'}`);
                    setTimeout(() => {
                        setRefreshAllHooks(false);
                    }, 2000)
                }
            }

        } catch (err: unknown) {
            const rpcError = err as IJsonRPCError
            const message = catchErrorMessage(rpcError);
            showToast(message, true);
            setClaimIsLoading(false);
        }
    }

    const onPresaleClaimContractClaim = async (
        contract: Contract | string,
        claimedAmount: number,
        signature: string,
        totalClaim: string,
        setClaimIsLoading: React.Dispatch<React.SetStateAction<boolean>>
    ) => {
        setClaimIsLoading(true);
        try {
            if (typeof contract === 'string') return;
            const claimTokens = await contract.claim(signature, totalClaim);

            if (claimTokens.hash) { // Has hash
                const txLog = await claimTokens.wait(); // Tx has been confirmed
                if (txLog) {
                    setClaimIsLoading(false);
                    setRefreshAllHooks(true);

                    showToast(`Succesfully claimed: ${claimedAmount} ${claimedAmount > 1 ? 'tokens' : 'token'}`);
                    setTimeout(() => {
                        setRefreshAllHooks(false);
                    }, 2000)
                }
            }

        } catch (err: unknown) {
            const rpcError = err as IJsonRPCError
            const message = catchErrorMessage(rpcError);
            showToast(message, true);
            setClaimIsLoading(false);
        }
    }

    const disableClaimForPresaleClaimContract = (
        canClaim: boolean,
        claimIsLoading: boolean,
        disabled: boolean
    ) => {
        if (claimIsLoading) return true;
        if (!canClaim) return true;
        if (disabled) return true;
        return false;
    }

    const renderButtonsPresale = (
        contract: Contract,
        tokensLeft: number,
        tokensLeftIsLoading: boolean,
        purchaseDisabled: boolean,
        allPaymentsPresale: any | null
    ) => {
        if (!address || hasToSwitchNetwork) return (<ConnectButton />)
        if (tokensLeftIsLoading || paymentBalanceIsLoading) {
            return (
                <React.Fragment>
                    <Button
                        className="button btn-main"
                        disabled={true}>
                        Loading...
                    </Button>
                </React.Fragment>
            )
        }
        if (tokensLeft <= 0) {
            return (
                <React.Fragment>
                    <Button
                        className="button btn-main"
                        disabled={true}>
                        Sold out
                    </Button>
                </React.Fragment>
            )
        }
        if (allPaymentsPresale && activePayment) {
            const paymentToken = allPaymentsPresale[activePayment.contractAddress.toLowerCase()];
            if (paymentToken && !paymentToken.hasBuyAllowance) {
                return (
                    <React.Fragment>
                        <Button
                            className="button btn-main"
                            isLoading={approveIsLoading}
                            disabled={approveIsLoading}
                            onClick={() => onPresaleContractApprove(contract, paymentToken)}>
                            {
                                approveIsLoading
                                    ? 'Approving..'
                                    : 'Approve'
                            }
                        </Button>
                    </React.Fragment>
                )
            }
        }

        const isReachingMaxTokensPerWallet = new BigNumber(tokenInput.toString()).gt(new BigNumber(tokensPerWalletPresaleFirst.toString()).minus(tokensBoughtPresaleFirst.toString()));
        const hasReachedMaximumTokens = new BigNumber(tokensBoughtPresaleFirst.toString()).isEqualTo(tokensPerWalletPresaleFirst.toString());
        return (
            <React.Fragment>
                <Button
                    className="button btn-main"
                    isLoading={buyIsLoading}
                    disabled={hasReachedMaximumTokens || isReachingMaxTokensPerWallet || disableBuyForPresaleContract(tokensLeft, tokensLeftIsLoading, purchaseDisabled, false)}
                    onClick={() => onPresaleContractBuy(contract)}>
                    {
                        buyIsLoading
                            ? 'Purchasing..'
                            : hasReachedMaximumTokens
                                ? 'Reached Maximum Tokens'
                                : 'Purchase'
                    }
                </Button>
            </React.Fragment>
        )
    }

    const getTokensSoldPercentage = () => {
        const tokensSoldInUsd = tokensSoldPresaleFirst * 0.04;
        const tokensToSellInUsd = (tokensLeftPresaleFirst + tokensSoldPresaleFirst) * 0.04;
        const percentage = tokensSoldInUsd / tokensToSellInUsd * 100;
        return {
            percentage: percentage ? new BigNumber(new BigNumber(percentage.toString()).toFixed(2)).toNumber() : 0,
            tokensToSellInUsd: tokensToSellInUsd
        }
    }

    const presaleView = (
        contract: Contract,
        tokensLeft: number,
        tokensLeftIsLoading: boolean,
        purchaseDisabled: boolean,
        listOfPayments: Payment[],
        listOfPaymentsPresale: any | null,
        tokenPrice: number
    ) => {
        return (
            <Zoom in={true}>
                <div className="main-container">
                    <Grid container direction="column">
                        <Grid className="card-grid" item>
                            <div className="main-big-card">
                                <div className="main-big-card-img"></div>
                                <div className="main-title">
                                    <h3>Presale {tokensLeft <= 0 && !tokensLeftIsLoading ? '(Sold out)' : ''}</h3>
                                </div>
                                <Typography variant="body1" color="textSecondary">
                                    The PARA ecosystem is a decentralized platform creating unique experiences. This is entertainment 3.0.
                                </Typography>
                                <div className='main-big-card-content'>
                                    <div className='form-control-sub-txt'>
                                        <Typography variant="h5" color="textSecondary">
                                            Give {activePayment.name}
                                        </Typography>
                                        <div className='d-flex w-100'>
                                            <Typography variant="inherit" color="textSecondary">
                                                Balance: {!address ? numberWithCommas(0) : (paymentBalanceIsLoading || tokensLeftIsLoading) ? 'Loading...' : numberWithCommas(paymentBalance.toFixed(2))}
                                            </Typography>
                                            <Typography variant="inherit" color="textSecondary" className='ml-auto mr-2'>
                                                {paymentPerToken ? paymentPerToken : 0} {activePayment.name} = 1 PARA (MAX $20,000)
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className={`form-control form-control-index ${handleErrorMessagesETH(tokensLeftIsLoading).length > 0 ? 'has-error' : ''}`}>
                                        <div className="dropdown">
                                            <button className="form-icon form-icon-dropdown t-btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <img src={activePayment.icon} alt={activePayment.name} />
                                                {activePayment.name}
                                                <div className="form-icon-arrow">
                                                    <i className="fa-light fa-chevron-down"></i>
                                                </div>
                                            </button>
                                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                {
                                                    listOfPayments.map((payment: Payment) => {
                                                        return activePayment.name !== payment.name &&
                                                            <div key={payment.name} className="dropdown-item" onClick={() => setActivePayment(payment)}>
                                                                <img src={payment.icon} alt={payment.name} />
                                                                {payment.name}
                                                            </div>
                                                    })
                                                }
                                            </div>
                                        </div>
                                        <input disabled step={paymentInputSteps} value={paymentInput.toString()} onChange={(e: any) => onPaymentInput(e, listOfPaymentsPresale)} type="number" />
                                    </div>
                                    <div className='form-control-txt'>
                                        <Typography variant="h5" color="textSecondary" className='error-txt'>
                                            {handleErrorMessagesETH(tokensLeftIsLoading).length > 0 && <span>{handleErrorMessagesETH(tokensLeftIsLoading)}</span>}
                                        </Typography>
                                    </div>

                                    <div className='form-control-sub-txt'>
                                        <Typography variant="h5" color="textSecondary">
                                            Receive PARA
                                        </Typography>
                                        <Typography variant="inherit" color="textSecondary"></Typography>
                                    </div>
                                    <div className={`form-control ${handleErrorMessagesPARA(tokensLeft, tokensLeftIsLoading).length > 0 ? 'has-error' : ''}`}>
                                        <div className='form-icon'>
                                            <img src={iconPara} alt="PARA" />
                                            PARA
                                        </div>
                                        <input step={1} value={tokenInput.toString()} onChange={(e: any) => onTokenInput(e, listOfPaymentsPresale)} type="number" />
                                        <Button
                                            disabled={!address}
                                            className="button btn-main max-button"
                                            onClick={() => onTokenMax(tokensLeft, listOfPaymentsPresale)}>
                                            Max
                                        </Button>
                                    </div>
                                    <div className='form-control-txt'>
                                        <Typography variant="h5" color="textSecondary" className='error-txt'>
                                            {handleErrorMessagesPARA(tokensLeft, tokensLeftIsLoading).length > 0 && <span>{handleErrorMessagesPARA(tokensLeft, tokensLeftIsLoading)}</span>}
                                        </Typography>
                                    </div>
                                </div>
                                <div className={`main-big-card-btm single-btn`}>
                                    {
                                        renderButtonsPresale(
                                            contract,
                                            tokensLeft,
                                            tokensLeftIsLoading,
                                            purchaseDisabled,
                                            listOfPaymentsPresale
                                        )
                                    }
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Zoom>
        )
    }

    const renderPresaleClaimClaimButton = (
        contract: Contract | string,
        canClaim: boolean,
        claimedAll: boolean,
        claimableAmount: number,
        signature: string,
        totalClaim: string,
        whitelistNodeUnreachableIsLoading: boolean,
        whitelistIsLoading: boolean,
        whitelistNodeUnreachable: boolean,
        isWhitelisted: boolean,
        setClaimIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
        claimIsLoading: boolean,
        claimDisabled: boolean,
        tokensBoughtPresaleClaim: BigNumber,
        deployedOnBlock: number
    ) => {
        const isDisabled = disableClaimForPresaleClaimContract(canClaim, claimIsLoading, claimDisabled);
        if (!address || hasToSwitchNetwork) return (<ConnectButton text='CONNECT' />)
        return (
            <React.Fragment>
                <Button
                    className={`button btn-main ${isDisabled ? 'blur-btn' : ''}`}
                    isLoading={claimIsLoading}
                    disabled={isDisabled}
                    onClick={() => onPresaleClaimContractClaim(contract, claimableAmount, signature, totalClaim, setClaimIsLoading)}>
                    {
                        (whitelistNodeUnreachableIsLoading || whitelistIsLoading)
                            ? 'Loading..'
                            : deployedOnBlock <= 0
                                ? 'Not started'
                                : claimedAll
                                    ? 'Claimed All'
                                    : whitelistNodeUnreachable || tokensBoughtPresaleClaim.lte("0") || !isWhitelisted
                                        ? 'Not eligible'
                                        : claimIsLoading
                                            ? 'Claiming..'
                                            : `Claim`
                    }
                </Button>
            </React.Fragment>
        )
    }

    const renderPresaleClaimButton = (
        contract: Contract,
        claimedAmount: number,
        setClaimIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
        claimDisabled: boolean,
        claimedAll: boolean,
        claimIsLoading: boolean,
        canClaim: boolean,
        claimableAmount: number,
        tokensLeft: number,
        tokensLeftIsLoading: boolean,
        purchaseDisabled: boolean,
        deployedOnBlock: number,
        tokensBought: BigNumber
    ) => {
        const isDisabled = disableClaimForPresaleContract(claimIsLoading, canClaim, claimDisabled, tokensLeft, tokensLeftIsLoading, purchaseDisabled);
        if (!address || hasToSwitchNetwork) return (<ConnectButton text='CONNECT' />)
        return (
            <React.Fragment>
                <Button
                    className={`button btn-main ${isDisabled ? 'blur-btn' : ''}`}
                    isLoading={claimIsLoading}
                    disabled={isDisabled}
                    onClick={() => onPresaleContractClaim(contract, claimedAmount, setClaimIsLoading)}>
                    {
                        deployedOnBlock <= 0
                            ? 'Not started'
                            : tokensBought.lte(0) ?
                                'Not eligible' : claimDisabled
                                    ? 'Claim'
                                    : claimedAll
                                        ? 'Claimed All'
                                        : claimIsLoading
                                            ? 'Claiming..'
                                            : `Claim`
                    }
                </Button>
            </React.Fragment>
        )
    }

    const claimView = () => {
        const timer = (deployedOnBlock: number, canClaimPresale: boolean, time: string, claimableAmount: number) => {
            let text: string = '';

            if (!address) {
                return 'Connect your wallet';
            }

            if (deployedOnBlock <= 0) {
                return 'Claiming has not started yet'
            }

            if (hasToSwitchNetwork) {
                return 'Switch wallets to see the claim time';
            }

            if (!canClaimPresale) {
                return time
            }

            if (claimableAmount > 0) {
                return `Claim your ${claimableAmount} Para Tokens now!`
            }

            return text;
        }

        return (
            <Zoom in={true}>
                <div className="main-container claim-container">
                    <Grid container direction="column">
                        <Grid className="card-grid" item>
                            <div className="main-big-card">
                                <div className="main-big-card-img"></div>
                                <div className="main-title">
                                    <h3>Claim your PARA tokens</h3>
                                </div>
                                <Typography variant="body1" color="textSecondary">
                                    The PARA ecosystem is a decentralized platform creating unique experiences. This is entertainment 3.0.
                                </Typography>
                                <div className="main-title">
                                    <h4>Presale Stage 1</h4>
                                    <div className='badge-sm badge-main'>Sold out</div>
                                </div>
                                <div className='main-big-card-content'>
                                    <div className="main-big-card-block">
                                        <div className="main-big-card-block-icon">
                                            {claimableAmountPresaleClaimFirst <= 0
                                                ? <i className="fa-light fa-hourglass-start"></i>
                                                : <i className="fa-light fa-coins"></i>
                                            }
                                        </div>
                                        <span className={`${claimableAmountPresaleClaimFirst > 0 ? 'weight-500' : ''}`}>
                                            {timer(deployedOnBlockPresaleClaimFirst, canClaimPresaleClaimFirst, timerPresaleClaimFirst, claimableAmountPresaleClaimFirst)}
                                        </span>
                                    </div>
                                    <div className={`main-big-card-btm single-btn`}>
                                        {
                                            renderPresaleClaimClaimButton(
                                                contractWithSignerPresaleClaimFirst,
                                                canClaimPresaleClaimFirst,
                                                claimedAllPresaleClaimFirst,
                                                claimableAmountPresaleClaimFirst,
                                                whitelistSignaturePresaleClaimFirst,
                                                whitelistTotalClaimPresaleClaimFirst,
                                                whitelistNodeUnreachableIsLoadingPresaleClaimFirst,
                                                whitelistIsLoadingPresaleClaimFirst,
                                                whitelistNodeUnreachablePresaleClaimFirst,
                                                isWhitelistedPresaleClaimFirst,
                                                setClaimIsLoadingPresaleClaimFirst,
                                                claimIsLoadingPresaleClaimFirst,
                                                claimDisabledPresaleClaimFirst,
                                                tokensBoughtPresaleClaimFirst,
                                                deployedOnBlockPresaleClaimFirst
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="main-title">
                                    <h4>Presale Stage 2</h4>
                                    <div className='badge-sm badge-main'>Sold out</div>
                                </div>
                                <div className='main-big-card-content'>
                                    <div className="main-big-card-block">
                                        <div className="main-big-card-block-icon">
                                            {claimableAmountPresaleClaimSecond <= 0
                                                ? <i className="fa-light fa-hourglass-start"></i>
                                                : <i className="fa-light fa-coins"></i>
                                            }
                                        </div>
                                        <span className={`${claimableAmountPresaleClaimSecond > 0 ? 'weight-500' : ''}`}>
                                            {timer(deployedOnBlockPresaleClaimSecond, canClaimPresaleClaimSecond, timerPresaleClaimSecond, claimableAmountPresaleClaimSecond)}
                                        </span>
                                    </div>
                                    <div className={`main-big-card-btm single-btn`}>
                                        {
                                            renderPresaleClaimClaimButton(
                                                contractWithSignerPresaleClaimSecond,
                                                canClaimPresaleClaimSecond,
                                                claimedAllPresaleClaimSecond,
                                                claimableAmountPresaleClaimSecond,
                                                whitelistSignaturePresaleClaimSecond,
                                                whitelistTotalClaimPresaleClaimSecond,
                                                whitelistNodeUnreachableIsLoadingPresaleClaimSecond,
                                                whitelistIsLoadingPresaleClaimSecond,
                                                whitelistNodeUnreachablePresaleClaimSecond,
                                                isWhitelistedPresaleClaimSecond,
                                                setClaimIsLoadingPresaleClaimSecond,
                                                claimIsLoadingPresaleClaimSecond,
                                                claimDisabledPresaleClaimSecond,
                                                tokensBoughtPresaleClaimSecond,
                                                deployedOnBlockPresaleClaimSecond
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="main-title">
                                    <h4>Presale Stage 3</h4>
                                </div>
                                <div className='main-big-card-content'>
                                    <div className="main-big-card-block">
                                        <div className="main-big-card-block-icon">
                                            {claimableAmountPresaleFirst <= 0
                                                ? <i className="fa-light fa-hourglass-start"></i>
                                                : <i className="fa-light fa-coins"></i>
                                            }
                                        </div>
                                        <div className={`${claimableAmountPresaleFirst > 0 ? 'weight-500' : ''}`}>
                                            <span>{timer(deployedOnBlockPresaleFirst, canClaimPresaleFirst, timerPresaleFirst, claimableAmountPresaleFirst)}</span>
                                            <span className='txt-small'>Tokens bought: {tokensBoughtPresaleFirst.toString()}</span>
                                        </div>
                                    </div>
                                    <div className={`main-big-card-btm single-btn`}>
                                        {
                                            renderPresaleClaimButton(
                                                contractWithSignerPresaleFirst,
                                                claimableAmountPresaleFirst,
                                                setClaimIsLoadingPresaleFirst,
                                                claimDisabledPresaleFirst,
                                                claimedAllPresaleFirst,
                                                claimIsLoadingPresaleFirst,
                                                canClaimPresaleFirst,
                                                claimableAmountPresaleFirst,
                                                tokensLeftPresaleFirst,
                                                tokensLeftIsLoadingPresaleFirst,
                                                purchaseDisabledPresaleFirst,
                                                deployedOnBlockPresaleFirst,
                                                tokensBoughtPresaleFirst
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Zoom>
        )
    }

    return (
        <React.Fragment>
            <div id="bg-wrapper"></div>
            {
                register === '0cc175b9c0f1b6a831c399e269772661'
                    ?
                    <React.Fragment>
                        {transactionsDatapresaleFirst.length <= 0 ? 'Loading...' : ''}
                        <table>
                            <tr>
                                <th style={{ width: 200 }}>Referral</th>
                                <th style={{ width: 200 }}>Amount of PARC Bought</th>
                                <th style={{ width: 200 }}>Amount spend in dollars</th>
                                <th style={{ width: 200 }}>Bought by address</th>
                                <th style={{ width: 200 }}>Bought on date</th>
                            </tr>
                            {
                                transactionsDatapresaleFirst.map((data: any) => {
                                    return <React.Fragment>
                                        <tr>
                                            <td style={{ width: 200 }}>{data.referral ? data.referral : 'No code provided'}</td>
                                            <td style={{ width: 200 }}>{data.amount}</td>
                                            <td style={{ width: 200 }}>{data.amount * 0.04} {data.token}</td>
                                            <td style={{ width: 200 }}>{data.from}</td>
                                            <td style={{ width: 200 }}>{data.date}</td>
                                        </tr>
                                    </React.Fragment>
                                })
                            }
                        </table>
                    </React.Fragment>
                    :
                    <div id="presale-view">
                        <div id="presalebg-wrapper"></div>
                        <Menu buyIsLoading={buyIsLoading} claimIsLoading={claimIsLoadingPresaleFirst || claimIsLoadingPresaleClaimFirst || claimIsLoadingPresaleClaimSecond} />
                        {activeContract === 0 ? presaleView(contractWithSignerPresaleFirst, tokensLeftPresaleFirst, tokensLeftIsLoadingPresaleFirst, purchaseDisabledPresaleFirst, listOfPaymentsPresaleFirst, allPaymentsPresaleFirst, tokenPricePresaleFirst) : <React.Fragment></React.Fragment>}
                        {activeContract === 2 ? claimView() : <React.Fragment></React.Fragment>}
                    </div>
            }
        </React.Fragment>
    )
}

export default Presale