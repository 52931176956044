import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import Presale from '../containers/Presale/Presale';

function Routing() {

    return (
        <Router>
            <Switch>
                <Route path="/" exact>
                    <Presale />
                </Route>
                <Route path="/:register" exact>
                    <Presale />
                </Route>
                <Route path="/:info" exact>
                    <Presale />
                </Route>
            </Switch>
        </Router>
    );
}

export default Routing;
