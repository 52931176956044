import BigNumber from "bignumber.js";
import { ethers } from "ethers";
import moment from "moment";
import { IWhitelistSignature } from "../types/IWhitelistSignature";
import { API_URL, chain, MAIN_ETHERSCAN_API_KEY } from "../utils/constants";

export const getWhitelist = async (presaleClaim: number, addr: string): Promise<IWhitelistSignature | null> => {
    const baseUrl = `${API_URL}/whitelist/${addr}/${presaleClaim}`;
    const res = await fetch(baseUrl, { method: "GET" });
    const json: IWhitelistSignature = await res.json();
    if (json) {
        return json;
    }
    return null;
}

export const getEthPrice = async (): Promise<number | null> => {
    const baseUrl = `https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd`;
    const res = await fetch(baseUrl, { method: "GET" });
    const json: any = await res.json();
    if (json && json.ethereum && json.ethereum.usd) {
        return json.ethereum.usd as number;
    }
    return null;
}

export const getAllTransactions = async (provider: any) => {
    const baseUrl = `${chain.ETHERSCAN_API}api?module=account&action=txlist&address=${chain.PRESALE_CONTRACT_FIRST.ADDRESS}&apikey=${MAIN_ETHERSCAN_API_KEY}`;
    const res = await fetch(baseUrl, { method: "GET" });
    const json: any = await res.json();
    if (json && json.result) {
        const results = json.result.filter((result: any) => result.functionName.includes('buyTokensWithReferral') || result.functionName.includes('buyTokens'));
        let newResults = []
        let count = 0;
        for (let result of results) {
            const inter = new ethers.utils.Interface(chain.PRESALE_CONTRACT_FIRST.ABI as any);
            const tx = await provider.getTransaction(result.hash);
            const decodedInput = inter.parseTransaction({ data: tx.data, value: tx.value});
            console.log('result', result);
            let data = {
                referral: decodedInput.args._artist ? decodedInput.args._artist : null,
                amount: new BigNumber(decodedInput.args._amount.toString()).toString(),
                from: result.from,
                date: moment.unix(result.timeStamp).format("DD/MM/YYYY, h:mm:ss a"),
                token: decodedInput.args._tokenAddress.toLowerCase() === chain.USDC_TOKEN.ADDRESS.toLowerCase() ? 'USDC' 
                : decodedInput.args._tokenAddress.toLowerCase() === chain.DAI_TOKEN.ADDRESS.toLowerCase() ? 'DAI'
                : decodedInput.args._tokenAddress.toLowerCase() === chain.USDT_TOKEN.ADDRESS.toLowerCase() && 'USDT'
            }
            newResults.push(data);
            count++;
        }
        if(count >= newResults.length) {
            return newResults;
        }

    }
}