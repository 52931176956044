import { IJsonRPCError } from "../types/IJsonRPCError";
import { toast } from "react-toastify";

export const truncateFromCenter = (value: string): string => {
    return value.substring(0, 8) + '...' + value.substring(value.length - 3, value.length);
}

export const numberWithCommas = (x: any) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const catchErrorMessage = (error: IJsonRPCError) => {
    console.log('catchErrorMessage', error)
    const message = error.data ? error.data.message : error.message;
    if (message.indexOf("underflow ") >= 0) {
        return 'Numeric underflow';
    }
    if (message.indexOf("ETH sent is incorrect") >= 0) {
        return 'ETH sent is in-correct';
    }
    if (message.indexOf("Need to send more ETH.") >= 0) {
        return 'Need to send more ETH.';
    }
    if (message.indexOf("Must purchase below or equal to `maxPurchasePerTx`") >= 0) {
        return 'The amount is above the max purchase per transaction';
    }
    if (message.indexOf("Reached max tokens per wallet") >= 0) {
        return 'Reached max tokens for wallet';
    }
    if (message.indexOf("Reached max tokens") >= 0) {
        return 'Reached max tokens';
    }
    if (message.indexOf("Amount must be higher than 0") >= 0) {
        return 'Amount must be higher than 0';
    }
    if (message.indexOf("Nothing to claim") >= 0) {
        return 'Nothing to claim';
    }
    if (message.indexOf("deployedOnBlock must be higher than 0") >= 0) {
        return 'deployedOnBlock must be higher than 0';
    }
    if (message.indexOf("No tokens bought to claim") >= 0) {
        return 'No tokens bought to claim';
    }
    if (message.indexOf("Already claimed total") >= 0) {
        return 'Already claimed total';
    }
    if (message.indexOf("No tokens left") >= 0) {
        return 'No tokens in contract to claim';
    }
    if (message.indexOf("Invalid signature") >= 0) {
        return 'Invalid signature';
    }
    if (message.indexOf("Purchase has been disabled") >= 0) {
        return 'Purchase has been disabled';
    }
    if (message.indexOf("Claim has been disabled") >= 0) {
        return 'Claim has been disabled';
    }
    if (message.indexOf("Referral link is invalid") >= 0) {
        return 'Referral link is invalid';
    }
    if (message.indexOf("Referral link is disabled") >= 0) {
        return 'This referral link has been disabled';
    }
    if (message.indexOf("Transaction reverted without a reason") >= 0) {
        return 'Transaction reverted without a reason';
    }
    return message;
}

export const showToast = (txt: string, error: boolean = false) => {
    return toast(txt, {
        toastId: txt + Math.random + Math.random,
        position: "top-right",
        autoClose: 7000,
        hideProgressBar: false,
        progressClassName: error ? 'error' : 'success',
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        className: error ? 'error' : 'success',
        progress: undefined,
    });
}