import BigNumber from 'bignumber.js';
import { useCallback, useEffect, useState } from 'react';

import { onChainProvider } from '../types/OnChainProvider';
import { BIG_DECIMAL_18 } from '../utils/constants';
import { useClaimDisabled } from './useClaimDisabled';
import { useContract } from './useContract';
import { useDeployedOnBlock } from './useDeployedOnBlock';
import { useTimer } from './useTimer';
import { useWeb3Context } from './useWeb3Context';
import useWhitelist from './useWhitelist';

export const usePresaleClaimFirst = (refresh: boolean = false) => {
    const { address, provider }: onChainProvider = useWeb3Context();
    const { contractWithProviderPresaleClaimFirst, tokenContractWithProvider } = useContract();
    const { deployedOnBlock } = useDeployedOnBlock(refresh, contractWithProviderPresaleClaimFirst);
    const [canClaimPresaleClaimFirst, setCanClaimPresaleClaimFirst] = useState<boolean>(false)
    const [claimedAllPresaleClaimFirst, setClaimedAllPresaleClaimFirst] = useState<boolean>(false)
    const [claimableAmountPresaleClaimFirst, setClaimableAmountPresaleClaimFirst] = useState<number>(0)
    const [tokensBoughtPresaleClaimFirst, setTokensBoughtPresaleClaimFirst] = useState<BigNumber>(new BigNumber("0"))
    const { isWhitelisted, whitelistNodeUnreachable, whitelistIsLoading, whitelistNodeUnreachableIsLoading, whitelistSignature, whitelistTotalClaim } = useWhitelist(refresh, 1);
    const deployedOnBlockPresaleClaimFirst = deployedOnBlock;
    const isWhitelistedPresaleClaimFirst = isWhitelisted;
    const whitelistNodeUnreachablePresaleClaimFirst = whitelistNodeUnreachable;
    const whitelistIsLoadingPresaleClaimFirst = whitelistIsLoading;
    const whitelistNodeUnreachableIsLoadingPresaleClaimFirst = whitelistNodeUnreachableIsLoading;
    const whitelistSignaturePresaleClaimFirst = whitelistSignature;
    const whitelistTotalClaimPresaleClaimFirst = whitelistTotalClaim;

    const { timer } = useTimer(refresh, contractWithProviderPresaleClaimFirst);
    const timerPresaleClaimFirst = timer;

    const { claimDisabled } = useClaimDisabled(refresh, contractWithProviderPresaleClaimFirst);
    const claimDisabledPresaleClaimFirst = claimDisabled;

    const resetValues = useCallback(() => {
        if (canClaimPresaleClaimFirst) {
            setCanClaimPresaleClaimFirst(false);
            setClaimableAmountPresaleClaimFirst(0)
        }
    }, [canClaimPresaleClaimFirst])

    useEffect(() => {
        const fetchCanClaim = async () => {
            if (typeof contractWithProviderPresaleClaimFirst === 'string') return console.log('ERROR fetchCanClaim');
            if (address && whitelistTotalClaim && new BigNumber(whitelistTotalClaim).gt(0)) {
                // Deployed on block > 0
                if (deployedOnBlock > 0) {
                    // const latestBlock = await provider.getBlock("latest")
                    // console.log('-----')
                    // console.log('deployedOnBlock', deployedOnBlock)
                    // console.log('latestBlock', latestBlock.number);
                    // console.log('-----')
                    const presaler = await contractWithProviderPresaleClaimFirst.presaler(address);
                    const tokensBought = new BigNumber(whitelistTotalClaim.toString())
                    setTokensBoughtPresaleClaimFirst(tokensBought)
                    const hasClaimedTotal = new BigNumber(presaler.toString()).eq(tokensBought.toString());
                    // console.log('presaler.toString()', presaler.toString())
                    // console.log('tokensBought', tokensBought.toString())
                    // console.log('-----')

                    // hasClaimedInTotal < tokensBought
                    if (!hasClaimedTotal) {
                        // console.log('hasClaimedTotal', hasClaimedTotal)
                        // console.log('tokensBought.gt(0)', tokensBought.gt(0))
                        // console.log('-----')
                        // tokensBought > 0
                        if (tokensBought.gt(0)) {
                            // tokens in contract > 0
                            const presaleContractTokenBalance = await tokenContractWithProvider.balanceOf(contractWithProviderPresaleClaimFirst.address);
                            const presaleContractHasBalance = new BigNumber(presaleContractTokenBalance.toString()).gt(0);
                            // console.log('presaleContractHasBalance', presaleContractHasBalance)
                            // console.log('presaleContractTokenBalance', presaleContractTokenBalance.toString())
                            // console.log('-----')
                            if (presaleContractHasBalance) {
                                // hasClaimedInTotal > 0
                                // Checking if the user has already claimed his TGE
                                const [claimableAmount, hasClaimedInTotal] = await contractWithProviderPresaleClaimFirst.getClaimable(address, whitelistTotalClaim);
                                const hasTotalClaimable = new BigNumber(hasClaimedInTotal.toString()).gt(0);
                                if (hasTotalClaimable) {
                                    // console.log('totalClaimable.toString()', hasClaimedInTotal.toString());
                                    // console.log('claimableAmount.toString()', claimableAmount.toString());
                                    // console.log('hasTotalClaimable', hasTotalClaimable);
                                    // console.log('-----')
                                    // tokens in contract >= totalClaimable
                                    const contractHasEnoughToClaim = new BigNumber(presaleContractTokenBalance.toString()).gte(claimableAmount.toString());
                                    const claimableAmnt = new BigNumber(claimableAmount.toString());
                                    // console.log('contractHasEnoughToClaim', contractHasEnoughToClaim);
                                    // console.log('presaleContractTokenBalance.toString()', presaleContractTokenBalance.toString());
                                    // console.log('hasClaimedInTotal.toString()', hasClaimedInTotal.toString());
                                    // console.log('claimableAmnt', claimableAmnt.toNumber());
                                    // console.log('-----')
                                    if (contractHasEnoughToClaim && claimableAmount.gt(0)) {
                                        setCanClaimPresaleClaimFirst(true);
                                        setClaimableAmountPresaleClaimFirst(new BigNumber(claimableAmnt.toString()).div(BIG_DECIMAL_18).toNumber());
                                    } else {
                                        resetValues();
                                        setClaimedAllPresaleClaimFirst(false);
                                    }
                                } else {
                                    resetValues();
                                    setClaimedAllPresaleClaimFirst(false);
                                }
                            } else {
                                resetValues();
                                setClaimedAllPresaleClaimFirst(false);
                            }
                        } else {
                            resetValues();
                            setClaimedAllPresaleClaimFirst(false);
                        }
                    } else {
                        resetValues();
                        setClaimedAllPresaleClaimFirst(true);
                    }
                } else {
                    resetValues();
                    setClaimedAllPresaleClaimFirst(false);
                }
            }
        }

        fetchCanClaim();
    }, [address, deployedOnBlock, provider, resetValues, tokenContractWithProvider, whitelistTotalClaim, refresh, contractWithProviderPresaleClaimFirst])

    return {
        canClaimPresaleClaimFirst,
        claimedAllPresaleClaimFirst,
        claimableAmountPresaleClaimFirst,
        deployedOnBlockPresaleClaimFirst,
        isWhitelistedPresaleClaimFirst,
        whitelistNodeUnreachablePresaleClaimFirst,
        whitelistIsLoadingPresaleClaimFirst,
        whitelistNodeUnreachableIsLoadingPresaleClaimFirst,
        whitelistSignaturePresaleClaimFirst,
        whitelistTotalClaimPresaleClaimFirst,
        timerPresaleClaimFirst,
        claimDisabledPresaleClaimFirst,
        tokensBoughtPresaleClaimFirst
    }
};