import { useMemo } from 'react';

import { onChainProvider } from '../types/OnChainProvider';
import {
    daiContract,
    presaleClaimContractFirst,
    presaleClaimContractSecond,
    presaleContractFirst,
    tokenContract,
    usdcContract,
    usdtContract,
} from '../utils/contracts';
import { useWeb3Context } from './useWeb3Context';

export const useContract = () => {
    const { provider }: onChainProvider = useWeb3Context();

    // PRESALE_CONTRACT_FIRST
    const contractWithSignerPresaleFirst = useMemo(() => {
        const signer = provider.getSigner();
        return presaleContractFirst(signer);
    }, [provider])

    const contractWithProviderPresaleFirst = useMemo(() => {
        return presaleContractFirst(provider);
    }, [provider])

    // PRESALECLAIM_CONTRACT_FIRST
    const contractWithSignerPresaleClaimFirst = useMemo(() => {
        const signer = provider.getSigner();
        return presaleClaimContractFirst(signer);
    }, [provider])

    const contractWithProviderPresaleClaimFirst = useMemo(() => {
        return presaleClaimContractFirst(provider);
    }, [provider])

    // PRESALECLAIM_CONTRACT_SECOND
    const contractWithSignerPresaleClaimSecond = useMemo(() => {
        const signer = provider.getSigner();
        return presaleClaimContractSecond(signer);
    }, [provider])

    const contractWithProviderPresaleClaimSecond = useMemo(() => {
        return presaleClaimContractSecond(provider);
    }, [provider])

    // TOKEN
    const tokenContractWithProvider = useMemo(() => {
        return tokenContract(provider);
    }, [provider])

    // DAI
    const contractWithSignerDai = useMemo(() => {
        const signer = provider.getSigner();
        return daiContract(signer);
    }, [provider])

    const contractWithProviderDai = useMemo(() => {
        return daiContract(provider);
    }, [provider])

    // USDT
    const contractWithSignerUsdt = useMemo(() => {
        const signer = provider.getSigner();
        return usdtContract(signer);
    }, [provider])

    const contractWithProviderUsdt = useMemo(() => {
        return usdtContract(provider);
    }, [provider])

    // USDC
    const contractWithSignerUsdc = useMemo(() => {
        const signer = provider.getSigner();
        return usdcContract(signer);
    }, [provider])

    const contractWithProviderUsdc = useMemo(() => {
        return usdcContract(provider);
    }, [provider])

    return {
        contractWithSignerPresaleClaimFirst,
        contractWithProviderPresaleClaimFirst,
        contractWithSignerPresaleClaimSecond,
        contractWithProviderPresaleClaimSecond,
        tokenContractWithProvider,
        contractWithSignerPresaleFirst,
        contractWithProviderPresaleFirst,
        contractWithSignerDai,
        contractWithProviderDai,
        contractWithSignerUsdt,
        contractWithProviderUsdt,
        contractWithSignerUsdc,
        contractWithProviderUsdc
    }
};